* {
	user-select: none;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	align-content: center;
	font-size: 2rem;
	font-weight: bold;
	text-align: center;
}

button {
	font-family: inherit;
	font-size: 40px;
	border-radius: 0.45rem;
	border: 20px aliceblue;
	cursor: pointer;
	padding: 15px 15px;
	text-align: center;
	text-decoration: none;
	margin: 10px;
	width: 7%;
	align-content: center;
}

button:active {
	transform: scale(0.95);
	transition: transform 0.1s;
}

.button-number {
	line-height: inherit;
	color: #61dafb;
	background-color: darkblue;
}

.down {
	background-color: #4CAF50;
	color: white;
	border: none;
	cursor: default;
}

.up {
	background-color: gray;
	color: white;
	border: none;
	cursor: pointer;
}
